import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onPortalAddNews,onPortalUpdateNews } from '../../APIs/news';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';

const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
const NewsView = ({ onChangeNews, item, onHandleClose, readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();

    const AddNewsSchema = Yup.object().shape({
        id: Yup.number(),        
        title: Yup.string().max(248).required('Title is required'),        
        description: Yup.string().required('Description is required'),        
        imageURL: Yup.string().max(252).required('ImageURL is required'),        
        targetURL: Yup.string().max(255).required('TargetURL is required'),        
        isActive: Yup.boolean(),
        displayOrder:Yup.number().min(0).required('DisplayOrder is required'),
    });

    const defaultValues = {
        id: item?.id ?? 0,
        title: item?.title ?? '',        
        description: item?.description ?? '',        
        imageURL: item?.imageURL ?? '',        
        targetURL: item?.targetURL ?? '',        
        isActive: item?.isActive ?? true,
        displayOrder:item?.displayOrder ?? 0,        
    };

    const methods = useForm({
        resolver: yupResolver(AddNewsSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const handleSave = (request) => {
        const newRequest = { ...request }

        if (request.id > 0) {
            updateNews(newRequest);
        }
        else {
            addNews(newRequest);
        }
    }

    const addNews = (request) => {
        onPortalAddNews(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChangeNews(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateNews= (request) => {
        onPortalUpdateNews(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChangeNews(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }
    
    return (
        <>
            <Helmet>
                <title>View News | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View News' : `${item ? 'Update' : 'Add'} News`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="title" label='Title' xs={12} readOnly={readOnly} />
                            <GridPaper name="description" label='Description' xs={12} readOnly={readOnly} />
                            <GridPaper name="imageURL" label='ImageURL' xs={12} readOnly={readOnly} />
                            <GridPaper name="targetURL" label='TargetURL' xs={12} readOnly={readOnly} />                            
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />
                            <GridPaper name="displayOrder" label='DisplayOrder' showZero xs={12} readOnly={readOnly} />                                                        
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

export default NewsView;