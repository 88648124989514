import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { debounce } from "lodash";
// @mui
import { Container, Typography, Stack, Chip, Switch, FormControlLabel, TextField, Button, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
// components
import { useSettingsContext } from '../../components/settings';
import CustomPagination from '../../components/custom-pagination';
import CustomModal from "../../components/custom-modal";
import Iconify from '../../components/iconify';
import CustomGrid from '../../components/custom-grid';

import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { getUserListColumn } from './appUserColumns';
import { getAppUsers, activeAppUser } from '../../APIs/appUsers';
import { AppUserProfileList } from './profile/AppUserProfileList'
import { AppUserView } from './AppUserView'

// ----------------------------------------------------------------------
import { useAuthContext } from '../../auth/useAuthContext';

export default function AppUserList() {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();
    const { user } = useAuthContext();

    const [totalUsers, setTotalUsers] = useState(0);
    const [users, setUsers] = useState([]);
    const [includeInactiveUsers, setIncludeInactiveUsers] = useState(true);
    const [term, setTerm] = useState();
    const [fullWidth] = useState(true);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10);
    const [openModal, setOpenModal] = useState(false);
    const [openAppUserModal, setOpenAppUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState();
    const [updateEmail, setUpdateEmail] = useState(false);

    const getUsers = (currentPage = 1) => {
        const request = { includeInactiveUsers, term, size, page: currentPage ?? page }
        setPage(request.page);

        getAppUsers(axiosPrivate, request, (response) => {
            const { data, total } = response;
            setUsers(data);
            setTotalUsers(total);
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getUsers, [includeInactiveUsers, term, size]);

    const onDeactiveUser = (id) => {
        activeAppUser(axiosPrivate, { id, isActive: false }, (response) => {
            if (response.status === 200) {
                getUsers(1);
            }
        });
    }

    const onActiveUser = (id) => {
        activeAppUser(axiosPrivate, { id, isActive: true }, (response) => {
            if (response.status === 200) {
                getUsers(1);
            }
        });
    }

    const onProfileClick = (id) => {
        const changeUser = users.find(c => c.id === id);
        setSelectedUser(changeUser);
        setOpenModal(true);
    }

    const onEdit = (id) => {
        setUpdateEmail(true);
        const changeUser = users.find(c => c.id === id);
        setSelectedUser(changeUser);
        setOpenAppUserModal(true);
    }

    const onUpdatePassword = (id) => {
        setUpdateEmail(false);
        const changeUser = users.find(c => c.id === id);
        setSelectedUser(changeUser);
        setOpenAppUserModal(true);
    }

    const handleChange = (event) => {
        setIncludeInactiveUsers(event.target.checked);
    };

    const handleSearch = (event) => {
        setTerm(event.target.value);
    }

    const handlePageChange = (pageIndex) => {
        setPage(pageIndex);     
        getUsers(pageIndex);   
    }

    const handlePageSizeChange = (pageSize) => {
        setSize(pageSize);
    }

    // const onAdd = () => {
    //     setOpenModal(true);
    // }    

    const onHandleClose = () => {
        setSelectedUser();
        setOpenModal(false);
        setOpenAppUserModal(false);
    }

    const handleUser = () => {
        getUsers(page);
        onHandleClose();
    }

    const debouncedChangeHandler = debounce(handleSearch, 500);

    const columns = getUserListColumn({ onEdit,onProfileClick, onActiveUser, onDeactiveUser, currentUser: user,onUpdatePassword });

    return (
        <>
            <Helmet>
                <title>Application Users | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'}>
                <Stack
                    flexGrow={1}
                    direction="column"
                    justifyContent="flex-start"
                    spacing={{ xs: 0.5, sm: 1.5 }}>
                    <Typography variant="h4" component="h4" paragraph>
                        Application Users <Chip label={totalUsers} />
                    </Typography>
                    <Stack
                        flexGrow={1}
                        direction="row"
                        justifyContent="flex-start"
                        spacing={{ xs: 0.5, sm: 1.5 }}>
                        <TextField
                            hiddenLabel
                            placeholder="Search..."
                            size="small"
                            onChange={debouncedChangeHandler}
                            fullWidth={fullWidth}
                            defaultValue={term}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={includeInactiveUsers}
                                    onChange={handleChange}
                                    inputProps={{ 'aria-label': 'controlled' }} />
                            }
                            label="Include Deactivated Users" />
                    </Stack>
                    <CustomGrid>
                        <DataGrid
                            sx={{
                                '.MuiDataGrid-columnHeaders': {
                                    backgroundColor: 'lightgray'
                                },
                                '.MuiDataGrid-columnSeparator': {
                                    display: 'none',
                                },
                                '&.MuiDataGrid-root': {
                                    border: 'none'
                                },
                                '.MuiDataGrid-footerContainer': {
                                    display: 'none'
                                }
                            }}
                            rows={users}
                            columns={columns}
                            disableColumnMenu
                            disableColumnFilter
                            disableRowSelectionOnClick
                            hideFooterPagination                            
                        />
                    </CustomGrid>
                    <CustomPagination pageSize={size} currentPage={page} total={totalUsers} handleChange={handlePageChange} handlePageSizeChange={handlePageSizeChange} />
                    <CustomModal open={openModal} onClose={onHandleClose}>
                        <AppUserProfileList selectedUser={selectedUser} />
                    </CustomModal>
                    <CustomModal open={openAppUserModal} onClose={onHandleClose}>
                        <AppUserView selectedUser={selectedUser} onChangeUser={handleUser} updateEmail={updateEmail}/>
                    </CustomModal>
                </Stack>
            </Container>
        </>
    );
}