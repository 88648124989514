import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import { Roles } from '../layouts/dashboard/nav/config-navigation';

// define abilities
const { can, cannot, build } = new AbilityBuilder(createMongoAbility);

can(Roles.Admin, ['/admin/dashboard', '/admin/users', '/admin/foods', '/admin/notifications', '/admin/section','/admin/labs','/admin/appusers'
    ,'/admin/diagnosis','/admin/symptoms','/admin/medication','/admin/comorbidities','/admin/locations','/admin/news'
]);
can(Roles.NonAdmin, ['/admin/section']);

const ability = build();

export default ability;