import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import FormControlLabel from '@mui/material/FormControlLabel';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Container, Grid, Paper, Typography, Alert, Checkbox, MenuItem } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '../../components/settings';
import FormProvider, { RHFAutocomplete, RHFCheckbox, RHFCheckboxWithLabel, RHFSelect } from '../../components/hook-form';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { onPortalAddFood, onPortalUpdateFood } from '../../APIs/foods';
import { getPortalFoodTypes } from '../../APIs/food-type';
import { getPortalFoodMeasures } from '../../APIs/food-measure';
import { getPortalDataSources } from '../../APIs/data-source';
import { getPortalBrands } from '../../APIs/brand';
import { GridPaper, GridPaperSelect, GridPaperSwitch } from '../../components/custom-grid-input';


const classes = {
    root: {
        flexGrow: 1
    },
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    }
};

/* eslint-disable react/prop-types */
const FoodView = ({ onChangeUser, item, onHandleClose, acids = [], readOnly = true }) => {
    const { themeStretch } = useSettingsContext();
    const axiosPrivate = useAxiosPrivate();

    const [types, setTypes] = useState([]);
    const [measures, setMeasures] = useState([]);
    const [sources, setSources] = useState([]);
    const [brands, setBrands] = useState([]);

    const [brandValue, setBrandValue] = useState(item?.brand ?? { id: 0, name: '' });
    const [brandInputValue, setBrandInputValue] = useState(item?.brand?.name ?? '');
    const [dataSourceValue, setDataSourceValue] = useState(item?.dataSource ?? { id: 0, name: '' });
    const [dataSourceInputValue, setDataSourceInputValue] = useState(item?.dataSource?.id ?? '');

    const [measureValue, setMeasureValue] = useState(item?.measure ?? { id: 0, name: '' });
    const [measureInputValue, setMeasureInputValue] = useState(item?.measure?.id ?? '');

    const [foodTypeValue, setFoodTypeValue] = useState(item?.foodType ?? { id: 0, name: '' });
    const [foodTypeInputValue, setFoodTypeInputValue] = useState(item?.foodType?.id ?? '');

    const [onlyVisible] = useState(true);
    const [showZero] = useState(true);
    const [showAminoAcidError, setShowAminoAcidError] = useState(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);
    const [isRequired, setIsRequired] = useState(false);

    const [foodAminoAcids, setFoodAminoAcids] = useState(item?.aminoAcids ?? acids);

    const getFoodTypes = () => {
        getPortalFoodTypes(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setTypes(response.data);
            }
            else {
                setTypes([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getFoodTypes, []);

    const getFoodMeasures = () => {
        getPortalFoodMeasures(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setMeasures(response.data);
            }
            else {
                setMeasures([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getFoodMeasures, []);

    const getDataSources = () => {
        getPortalDataSources(axiosPrivate, {}, (response) => {
            if (response.status === 200) {
                setSources(response.data);
            }
            else {
                setSources([]);
            }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getDataSources, []);

    const getBrands = (value) => {
        if (value) {
            getPortalBrands(axiosPrivate, { startsWith: value }, (response) => {
                if (response.status === 200) {
                    setBrands(response.data);
                }
                else {
                    setBrands([]);
                }
            });
        }
        else {
            setBrands([]);
        }
    }


    const AddFoodSchema = Yup.object().shape({
        id: Yup.number(),
        name: Yup.string().max(200).required('Food Name is required'),
        brandName: Yup.string().max(50).required('Brand Name is required'),
        foodTypeId: Yup.string().required('Type is required'),
        // measureId: Yup.number().min(1, 'Measure is required'),        
        servingSize: Yup.number().positive("Serving Size must be positive").required('Serving Size is required'),
        measureId: Yup.string().required('Measure is required'),
        servingLabel: isRequired ? Yup.string().required('Serving Label is required') : Yup.string(),
        totalServings: Yup.string().required('Total Serving is required').min(1, "Total Serving must be greater than 0."),
        protein_grams: Yup.number().required('Protein is required'),
        calories: Yup.number(),
        exchanges: Yup.number(),
        notes: Yup.string(),
        proteinEQ_grams: Yup.number(),
        weight_grams: Yup.number(),
        keywords: Yup.string().max(255),
        isActive: Yup.boolean(),
        isPremium: Yup.boolean(),
        isRecipe: Yup.boolean(),
        isVerified: Yup.boolean().default(false),
        isSimplifiedTracking: Yup.boolean(),
        baseHMPFoodId: Yup.number(),
        aminoAcids: Yup.array()
            .of(
                Yup.object().shape({
                    aminoAcidId: Yup.number(),
                    name: Yup.string(),
                    value: Yup.number().nullable(true).transform((_, val) => {
                        const ret = (val !== null && val !== "") ? Number(val) : null
                        return ret;
                    }),
                    isChecked: Yup.boolean(),
                    dataSourceId: Yup.string().when(["isChecked", "value"], {
                        is: (isChecked, value) => (isChecked === false && value === null),
                        then: () => Yup.string().required('Data source is required.'),
                        otherwise: () => Yup.string().notRequired()
                    }),
                })
            ),
        rankingWeight: Yup.number().min(0, 'Ranking Weight should not be less than zero'),
    });

    const defaultValues = {
        id: item?.id ?? 0,
        name: item?.name ?? '',
        brandName: item?.brand?.name ?? '',
        foodTypeId: item?.foodType?.id ?? '',
        measureId: item?.measure?.id ?? '',
        servingSize: item?.servingSize ?? 0,
        servingLabel: item?.servingLabel ?? '',
        totalServings: item?.totalServings ?? 1,
        protein_grams: item?.protein_grams ?? 0,
        calories: item?.calories ?? 0,
        exchanges: item?.exchanges ?? 0,
        notes: item?.notes ?? '',
        proteinEQ_grams: item?.proteinEQ_grams ?? 0,
        weight_grams: item?.weight_grams ?? 0,
        keywords: item?.keywords ?? '',
        isActive: item?.isActive ?? true,
        isPremium: item?.isPremium ?? false,
        isRecipe: item?.isRecipe ?? false,
        isVerified: item?.isVerified ?? false,
        isSimplifiedTracking: item?.isSimplifiedTracking ?? false,
        baseHMPFoodId: item?.baseHMPFoodId ?? 0,
        aminoAcids: item?.aminoAcids?.map((a) => {
            const result = { aminoAcidId: a.aminoAcid.id, name: a.aminoAcid.name, dataSourceId: a.aminoAcid.dataSourceId, value: a.id > 0 ? a.value : null, isChecked: a.isChecked }
            return result;
        }) ?? acids.map((a) => {
            const result = { aminoAcidId: a.id, name: a.name, value: null, dataSourceId: '', isChecked: a.isChecked }
            return result;
        }),
        // dataSourceId: item?.dataSource?.id ?? '',
        rankingWeight: item?.rankingWeight ?? 0,
        createdAt: item ? new Date(item.createdAt) : '',
        updatedAt: item ? new Date(item.updatedAt) : '',
        updatedAtByAdmin: item ? new Date(item.updatedAtByAdmin) : '',
        labelData: item ? '1g PRO' : null
    };

    const methods = useForm({
        resolver: yupResolver(AddFoodSchema),
        defaultValues
    });

    const {
        setValue,
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;



    const handleSave = (request) => {
        setIsSubmitSuccessful(true);
        const newRequest = { ...request, brand: brandValue, aminoAcids: foodAminoAcids }

        setShowAminoAcidError(false);
        if (newRequest.aminoAcids.filter(x => x.value === null && x.isChecked === false).length > 0) {
            setShowAminoAcidError(true);
            setIsSubmitSuccessful(false);
            return false;
        }
        if (request.id > 0) {
            updateFood(newRequest);
        }
        else {
            addFood(newRequest);
        }
        setIsSubmitSuccessful(false);
        return true;
    }

    const addFood = (request) => {
        onPortalAddFood(axiosPrivate, request, (response) => {
            if (response.status === 201) {
                onChangeUser(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const updateFood = (request) => {
        onPortalUpdateFood(axiosPrivate, request, (response) => {
            if (response.status === 200) {
                onChangeUser(response.data);
            }
            else {
                console.log('error', response);

                reset();

                setError('afterSubmit', {
                    ...response,
                    message: response.data,
                });
            }
        });
    }

    const renderAminoAcids = (aminoAcids) =>
        <>
            <Grid container item xs={12} spacing={1}>
                <Grid item xs={0}>
                    <Paper style={classes.paper}>
                        <Typography variant="h6" xs={12}>Amino Acids</Typography>
                    </Paper>
                </Grid>
                {
                    showAminoAcidError ?
                        <Grid item xs={12}>
                            <Paper style={classes.root}>
                                <Alert severity="error">Please mark checkbox if you want to keep values null !</Alert>
                            </Paper>
                        </Grid>
                        : null
                }
            </Grid>
            {
                aminoAcids && aminoAcids.length > 0 ?
                    <>
                        <Grid item xs={12} style={{ marginLeft: '39%' }}>
                            <Paper style={classes.root}>
                                <Alert severity="warning">Mark checkbox to keep amino acid value as null !</Alert>
                            </Paper>
                        </Grid>
                        {/* {JSON.stringify(aminoAcids)} */}
                        {
                            aminoAcids.map((acid, index) =>
                                <Grid key={index} container item xs={12} spacing={1}>
                                    <GridPaper name={`aminoAcids.${index}.name`} label='Name' xs={3} />
                                    <GridPaper name={`aminoAcids.${index}.value`} label='Value' type="number" xs={2} readOnly={readOnly} showZero={showZero} />
                                    {/* <GridPaperTest name={`aminoAcids.${index}.value`} label='Value' value={aminoAcids[index].value} type="number" xs={2} readOnly={readOnly} showZero={showZero} />                                     */}
                                    <Grid item>
                                        <Paper style={classes.paper}>
                                            <RHFCheckboxWithLabel name={`aminoAcids.${index}.isChecked`} />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Paper style={classes.paper}>
                                            <RHFSelect name={`aminoAcids.${index}.dataSourceId`} label="Data Source" value={aminoAcids[index].dataSourceId ?? ''}
                                                onChange={(event, newValue) => {
                                                    setFoodAminoAcids(foodAminoAcids.map((x, i) => {
                                                        if (i === index) {
                                                            return { ...x, dataSourceId: newValue.props.value }
                                                        }
                                                        return x;
                                                    }));
                                                }}
                                            >
                                                {
                                                    sources.map((dataSourcevalue, dataSourceIndex) =>
                                                        <MenuItem value={dataSourcevalue.id} selected={dataSourcevalue.id === aminoAcids[index].dataSourceId}>{dataSourcevalue.name}</MenuItem>
                                                    )}

                                            </RHFSelect>
                                            {/* <RHFAutocomplete
                                                autoSelect
                                                name={`aminoAcids.${index}.dataSourceId`}
                                                label="Data Source"
                                                readOnly={readOnly}
                                                value={aminoAcids[index].dataSource.name}
                                                inputValue={aminoAcids[index].dataSource.name}
                                                onChange={(event, newValue) => {
                                                    setValue(`aminoAcids.${index}.dataSourceId`, '', { shouldValidate: true });
                                                    if (newValue) {
                                                        setValue(`aminoAcids.${index}.dataSourceId`, parseInt(newValue.id, 10), { shouldValidate: true });
                                                    }
                                                }}
                                                onInputChange={(event, value) => {
                                                    if (value) {
                                                        setValue(`aminoAcids.${index}.dataSourceId`, parseInt(value.id, 10), { shouldValidate: true });
                                                        // setDataSourceValue(value);
                                                        // setDataSourceInputValue(value ?? '');
                                                    }
                                                }}
                                                getOptionLabel={(option) => option?.name ?? ''}
                                                options={sources}
                                            /> */}
                                        </Paper>
                                    </Grid>
                                </Grid>
                            )
                        }
                    </>
                    : <Grid container item xs={12} spacing={1}>
                        <Grid item xs={12}>
                            <Paper style={classes.paper}>
                                <Typography variant="h12" xs={12}>No Amino Acids</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
            }
        </>

    return (
        <>
            <Helmet>
                <title>View Food | flok</title>
            </Helmet>

            <Container maxWidth={themeStretch ? false : 'xl'} spacing={{ xs: 0.5, sm: 1.5 }}>
                <FormProvider methods={methods} onSubmit={handleSubmit(handleSave)}>
                    <div style={classes.root}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" paragraph>
                                    {readOnly ? 'View Food' : `${item ? 'Update' : 'Add'} Food`}
                                </Typography>
                                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                            </Grid>
                            <GridPaper name="name" label='Name' xs={12} readOnly={readOnly} />
                            <Grid item xs={4}>
                                <Paper style={classes.paper}>
                                    <RHFAutocomplete
                                        name="brandName"
                                        label="Brand"
                                        readOnly={readOnly}
                                        value={brandValue}
                                        onChange={(event, newValue) => {
                                            setBrandValue('');
                                            setBrandInputValue('');
                                            setValue('brandName', '', { shouldValidate: true });

                                            if (newValue) {
                                                setBrandValue(newValue);
                                                setBrandInputValue(newValue.name ?? '');
                                                setValue('brandName', newValue.name, { shouldValidate: true });
                                            }

                                        }}
                                        inputValue={brandInputValue}
                                        onInputChange={(event, value) => {
                                            setBrandInputValue(value ?? '');
                                            setBrandValue({ id: 0, name: value });
                                            setValue('brandName', value, { shouldValidate: true });
                                            getBrands(value);
                                        }}
                                        getOptionLabel={(option) => option?.name ?? ''}
                                        options={brands}
                                        freeSolo
                                    />
                                </Paper>
                            </Grid>
                            {/* <GridPaperSelect name="foodTypeId" label='Type' readOnly={readOnly} items={types} /> */}
                            <Grid item xs={4}>
                                <Paper style={classes.paper}>
                                    <RHFAutocomplete
                                        name="foodTypeId"
                                        label="Type"
                                        readOnly={readOnly}
                                        autoSelect
                                        value={foodTypeValue}
                                        onChange={(event, newValue) => {
                                            setFoodTypeValue('');
                                            setFoodTypeInputValue('');
                                            setValue('foodTypeId', '', { shouldValidate: true });
                                            if (newValue) {
                                                setFoodTypeValue(newValue);
                                                setFoodTypeInputValue(newValue.name ?? '');
                                                setValue('foodTypeId', parseInt(newValue.id, 10), { shouldValidate: true });
                                            }

                                        }}
                                        inputValue={foodTypeInputValue}
                                        onInputChange={(event, value) => {
                                            if (value) {
                                                setFoodTypeValue(value);
                                                setFoodTypeInputValue(value ?? '');
                                            }
                                        }}
                                        getOptionLabel={(option) => option?.name ?? ''}
                                        options={types}
                                    />
                                </Paper>
                            </Grid>
                            {/* <GridPaperSelect name="measureId" label='Measure' readOnly={readOnly} items={measures} /> */}
                            <Grid item xs={4}>
                                <Paper style={classes.paper}>
                                    <RHFAutocomplete
                                        autoSelect
                                        name="measureId"
                                        label="Measure"
                                        readOnly={readOnly}
                                        value={measureValue}
                                        onChange={(event, newValue) => {
                                            setIsRequired(false);
                                            if (newValue.id === 2) {
                                                setIsRequired(true);
                                            }
                                            setMeasureValue('');
                                            setMeasureInputValue('');
                                            setValue('measureId', '', { shouldValidate: true });
                                            if (newValue) {
                                                setMeasureValue(newValue);
                                                setMeasureInputValue(newValue.name ?? '');
                                                setValue('measureId', parseInt(newValue.id, 10), { shouldValidate: true });
                                            }

                                        }}
                                        inputValue={measureInputValue}
                                        onInputChange={(event, value) => {
                                            if (value) {
                                                setMeasureValue(value);
                                                setMeasureInputValue(value ?? '');
                                            }
                                        }}
                                        getOptionLabel={(option) => option?.name ?? ''}
                                        options={measures}
                                    />
                                </Paper>
                            </Grid>
                            {/* <GridPaperSelect name="dataSourceId" label='Data Source' readOnly={readOnly} items={sources} /> */}
                            <Grid item xs={4}>
                                <Paper style={classes.paper}>
                                    <RHFAutocomplete
                                        autoSelect
                                        name="dataSourceId"
                                        label="Data Source"
                                        readOnly={readOnly}
                                        value={dataSourceValue}
                                        onChange={(event, newValue) => {
                                            setDataSourceValue('');
                                            setDataSourceInputValue('');
                                            setValue('dataSourceId', '', { shouldValidate: true });
                                            if (newValue) {
                                                setDataSourceValue(newValue);
                                                setDataSourceInputValue(newValue.name ?? '');
                                                setValue('dataSourceId', parseInt(newValue.id, 10), { shouldValidate: true });
                                            }

                                        }}
                                        inputValue={dataSourceInputValue}
                                        onInputChange={(event, value) => {
                                            if (value) {
                                                setDataSourceValue(value);
                                                setDataSourceInputValue(value ?? '');
                                            }
                                        }}
                                        getOptionLabel={(option) => option?.name ?? ''}
                                        options={sources}
                                    />
                                </Paper>
                            </Grid>
                            <GridPaper name="servingLabel" label='Serving Label' readOnly={readOnly} />
                            <GridPaper name="servingSize" label='Serving' type="number" readOnly={readOnly} />
                            <GridPaper name="totalServings" label='Total Servings' type="number" readOnly={readOnly} />
                            <GridPaper name="protein_grams" label='Proteins (g)' type="number" readOnly={readOnly} showZero />
                            <GridPaper name="proteinEQ_grams" label='ProteinEQ (g)' type="number" readOnly={readOnly} />
                            <GridPaper name="calories" label='Calories' type="number" readOnly={readOnly} />
                            <GridPaper name="weight_grams" label='Weight (g)' type="number" readOnly={readOnly} />
                            <GridPaper name="exchanges" label='Phe Exchanges' type="number" readOnly={readOnly} />
                            <GridPaper name="rankingWeight" label='Ranking Weight' type="number" showZero={showZero} readOnly={readOnly} />
                            {item && <GridPaper name="id" label='Food Id' readOnly={onlyVisible} />}
                            <GridPaper name="keywords" label='Keywords' xs={12} readOnly={readOnly} />
                            <GridPaper name="notes" label='Notes' xs={12} readOnly={readOnly} />
                            <GridPaperSwitch name="isActive" label='Is Active' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isRecipe" label='Is Recipe' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isVerified" label='Is Lab Verified' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isSimplifiedTracking" label='Is Simplified' xs={3} readOnly={readOnly} />
                            <GridPaperSwitch name="isTrending" label='Is Trending' xs={3} readOnly={readOnly} />
                            {renderAminoAcids(foodAminoAcids)}
                            <Grid container item xs={12} spacing={1}>
                                <Grid item xs={2}>
                                    <Paper style={classes.paper}>
                                        {!readOnly &&
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                loading={isSubmitSuccessful || isSubmitting}
                                            >
                                                {item ? "Update" : "Add"}
                                            </LoadingButton>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                            {/* {item ?
                                <>
                                    <Grid item xs={12}>
                                        <GridPaper name="labelData" label='Label Data' />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GridPaper name="createdAt" label='Date Added' xs={4} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GridPaper name="updatedAt" label='Last updated for public' xs={4} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <GridPaper name="updatedAtByAdmin" label='Last updated by admin' xs={4} />
                                    </Grid>
                                </>
                                :
                                null
                            } */}
                        </Grid>
                    </div>
                </FormProvider>
            </Container>
        </>
    )
}

export default FoodView;