import { Button, Tooltip,Box, Link } from '@mui/material';
import Moment from 'react-moment';
import SvgColor from '../../components/svg-color';
import { StyledIcon } from '../../components/nav-section/vertical/styles';
import Label from '../../components/label';

const icon = (name) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    edit: icon('ic_edit'),
    activate: icon('ic_start'),
    deactivate: icon('ic_stop'),
    profile: icon('ic_user'),
    male: icon('ic_male'),
    female: icon('ic_female'),
    nonbinary:icon('ic_nonbinary'),
    verified: icon('ic_verified'),
    not_verified: icon('ic_not_verified'),
    password_reset: icon('ic_passwordreset'),
}

const renderStausComponent = (status, text) => (<Label
    variant="soft"
    color={status === true ? 'success' : 'error'}
    sx={{ textTransform: 'capitalize' }}
>
    {text}
</Label>)


const renderStatus = (params) => {
    if (params.row.isActive) {
        return renderStausComponent(params.row.isActive, "Active");
    }
    return renderStausComponent(params.row.isActive, "Deactivated");
}



const renderGender = (params) => {
    if (params.row.genderId === 1) {
        return <StyledIcon>{ICONS.male}</StyledIcon>
        // return renderStausComponent(params.row.genderId, "Male");
    }
    if (params.row.genderId ===2 ) {
        // return renderStausComponent(params.row.genderId, "Female");
        return <StyledIcon>{ICONS.female}</StyledIcon>
    }
    if (params.row.genderId === 3) {
         return renderStausComponent(params.row.genderId, "Nonbinary");        
    }
    return renderStausComponent(params.row.genderId, "Prefer Not To Answer");
}

const renderDate = (params) =>(         
<Moment format="MM/DD/YYYY">
            {params}
        </Moment>    
);


/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

export const getUserListColumn = (props) => [
    {
        field: '#', headerName: 'Actions', width: 200, sortable: false, frozen : true,
        renderCell: (params) => (
            <>
            <CustomizedButton text="Edit" onClick={() => { props.onEdit(params.row.id); }} styledIcon={ICONS.edit} />
            <CustomizedButton text="Update Password" onClick={() => { props.onUpdatePassword(params.row.id); }} styledIcon={ICONS.password_reset} />
                <CustomizedButton text="Profiles" onClick={() => { props.onProfileClick(    params.row.id); }} styledIcon={ICONS.profile} />                
                {params.row.id !== props.currentUser.id ? (params.row.isActive ?
                    <CustomizedButton text="Deactivate" onClick={() => { props.onDeactiveUser(params.row.id); }} styledIcon={ICONS.deactivate} /> :
                    <CustomizedButton text="Activate" onClick={() => { props.onActiveUser(params.row.id); }} styledIcon={ICONS.activate} />) : null}
            </>
        )
    },
    { field: 'id', headerName: 'User Id', width: 200, sortable: true },
    { field: 'firstName', headerName: 'First Name', width: 200, sortable: true },
    { field: 'lastName', headerName: 'Last Name', width: 200, sortable: true },
    { field: 'email', headerName: 'Email', width: 200, sortable: false },
    { field: 'dateOfBirth', headerName: 'Date Of Birth', width: 200, sortable: false,renderCell: (params) => renderDate(params.row.dateOfBirth) },
    { field: 'genderId', headerName: 'Gender', width: 100, sortable: false, renderCell: (params) => renderGender(params) },
    { field: 'isEmailVerified', headerName: 'Email Verified', width: 150, sortable: false, renderCell: (params) => (
        <>  
            {params.row.id !== props.currentUser.id ? (!params.row.isEmailVerified ?
                <CustomizedButton text="Not Verified" styledIcon={ICONS.not_verified} /> :
                <CustomizedButton text="Verified" styledIcon={ICONS.verified} />) : null}
        </>
    ) },    
    { field: 'createdAt', headerName: 'Created Date', width: 150, sortable: true,renderCell: (params) => renderDate(params.row.createdAt) },    
    {
        field: 'isActive', headerName: 'Status', width: 100, sortable: false,
        renderCell: (params) => renderStatus(params)
    },        
];