import { Grid, Paper, MenuItem } from '@mui/material';
import { RHFTextField, RHFSwitch, RHFSelect, RHFAutocomplete } from '../hook-form';

const classes = {
    paper: {
        padding: 5,
        textAlign: "center",
        color: "inherit"
    },
    paperSelect: {
        padding: 5,
        color: "inherit"
    }
};


/* eslint-disable react/prop-types */
export const GridPaper = ({ xs, name, label, type = "text", fullWidth = true, readOnly = true, showZero = false }) =>
    <Grid item xs={xs ?? (type === 'number' ? 2 : 4)}>
        <Paper style={classes.paper}>
            <RHFTextField
                fullWidth={fullWidth}
                label={label}
                type={type}
                name={name}
                showZero={showZero}
                InputProps={{
                    readOnly,
                    inputProps: { min: 0,step:'any'}
                }}
            />
        </Paper>
    </Grid>

/* eslint-disable react/prop-types */
export const GridPaperSwitch = ({ xs, name, label, readOnly = true }) =>
    <Grid item xs={xs ?? 4}>
        <Paper style={classes.paper}>
            <RHFSwitch
                label={label}
                name={name}
                disabled={readOnly}
            />
        </Paper>
    </Grid>

/* eslint-disable react/prop-types */
export const GridPaperSelect = ({ xs, name, label, items, readOnly = true }) =>
    <Grid item xs={xs ?? 4}>
        <Paper style={classes.paperSelect}>
            <RHFSelect name={name} disabled={readOnly} label={label}>
                <MenuItem disabled={readOnly} value={0}>--Select {label}--</MenuItem>
                {items && items.length > 0 && items.map((item, index) => <MenuItem disabled={readOnly} key={index} value={item.id}>{item.name}</MenuItem>)}
            </RHFSelect>
        </Paper>
    </Grid>

/* eslint-disable react/prop-types */
export const GridPaperAutocompleteSelect = ({ xs, name, label, value, onChange, inputValue, onInputChange, getOptionLabel, options, readOnly = true }) =>
    <Grid item xs={xs ?? 4}>
        <Paper style={classes.paperSelect}>
            <RHFAutocomplete
                name={name}
                label={label}
                readOnly={readOnly}
                autoSelect
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                getOptionLabel={getOptionLabel}
                options={options}
            />
        </Paper>
    </Grid>