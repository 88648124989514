import { useState } from 'react';
import {
    Stack,
    Typography,
    Tooltip,
    Button
} from '@mui/material';
import { StyledIcon } from '../../components/nav-section/vertical/styles';
import SvgColor from '../../components/svg-color';

const icon = (name, color) => (
    <SvgColor src={`/assets/icons/setting/${name}.svg`} sx={{ width: 1, height: 1, color }} />
);

const ICONS = {
    correct: icon('ic_correct', 'green'),
    wrong: icon('ic_wrong', 'red'),
    edit: icon('ic_edit'),
    delete: icon('ic_delete')
}

const renderStatusIcon = (status) => {
    if (status) {
        return <StyledIcon>{ICONS.correct}</StyledIcon>
    }
    return <StyledIcon>{ICONS.wrong}</StyledIcon>
}

const renderName = (params) => <Tooltip title={params.row.name}>
    <Stack direction="column">        
            {params.row.name}        
    </Stack>
</Tooltip>

/* eslint-disable react/prop-types */
const CustomizedButton = ({ text, styledIcon, onClick }) =>
    <Tooltip title={text}>
        <Button onClick={onClick}>
            <StyledIcon>{styledIcon}</StyledIcon>
        </Button>
    </Tooltip>

/* eslint-disable react/prop-types */
const RenderActionOptions = ({ onEdit, row }) =>
    <CustomizedButton text="Edit" onClick={(e) => {
        e.stopPropagation();
        onEdit(row.id);
    }} styledIcon={ICONS.edit} />



/* eslint-disable react/prop-types */
export const getListColumns = ({ onEdit }) => [
    {
        field: 'action', headerName: 'Actions', width: 200, sortable: false,
        renderCell: (params) => <RenderActionOptions onEdit={onEdit} row={params.row} />
    },
    {
        field: 'name', headerName: 'Name', width: 300, sortable: false,
        renderCell: (params) => renderName(params)
    },
    {
        field: 'isActive', headerName: 'IsActive', width: 150, sortable: false,
        renderCell: (params) => renderStatusIcon(params.row.isActive)
    }    
];