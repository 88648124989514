export const getPortalNews = (API, request, callback) => {
    API.get(`news`, { params: request }).then(response => {
        callback(response.data);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalAddNews = (API, request, callback) => {
    API.post(`news`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}

export const onPortalUpdateNews = (API, request, callback) => {
    API.put(`news/${request.id}`, request).then(response => {
        callback(response);
    }).catch(error => {
        console.log(error);
    });
}